import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('fetchClients')
        if (ability.can('read', 'clients') || ability.can('read', 'cameras')) {
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.clients.fetchingClientList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'clientname') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort display name desc')
                  return b.display_name.localeCompare(a.display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort display name asc')
                  return a.display_name.localeCompare(b.display_name)
                })
              }
            }
            if (qvars.sortBy === 'parent_client') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort parent name desc')
                  return b.parent_clientname.localeCompare(a.parent_clientname)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort parent name asc')
                  return a.parent_clientname.localeCompare(b.parent_clientname)
                })
              }
            }
            if (qvars.sortBy === 'status') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort status desc')
                  return b.status.localeCompare(a.status)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort status asc')
                  return a.status.localeCompare(b.status)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              // filter by status
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              // filter by Services
              console.log('filter by Services')
              console.log(qvars.service)
              console.log(item.services)
              if (typeof qvars.service !== 'undefined' && qvars.service !== null) {
                addThisItem = false
                if (typeof item.services !== 'undefined') {
                  Object.keys(item.services).forEach(e => {
                    if (e === qvars.service && item.services[e] === true) {
                      console.log('Add the Item')
                      addThisItem = true
                    }
                  })
                }
              }
              // filter by search term
              if (qvars.q !== null
                && !item.clientname.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.display_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.parent_clientname.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              console.log(addThisItem)
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('clients') === null
            || parseInt(localStorage.getItem('clients_lastRefresh'), 10) + 1440000 < timeDiff // cache for 24 hours
          ) {
            console.log('Refreshing Clients')
            const apiName = 'APIGateway'
            const path = '/client'
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                items.forEach(element => {
                  if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'active'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                  if (element.parent_clientname === null) {
                    // eslint-disable-next-line no-param-reassign
                    element.parent_clientname = ''
                  }
                })
                // Store Client data in local storage
                localStorage.setItem('clients', JSON.stringify(items))
                localStorage.setItem('clients_lastRefresh', d1.getTime())
                theResponse.data.clients = resortFilterItems(items)
                theResponse.data.totalClients = totalItems
                console.log(theResponse)
                store.state.clients.fetchingClientList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.clients.fetchingClientList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Clients Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem('clients'))
            theResponse.data.clients = resortFilterItems(itemsData)
            theResponse.data.totalClients = totalItems
            // console.log(theResponse)
            store.state.clients.fetchingClientList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchClient(ctx, clientname) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'clients')) {
          const apiName = 'APIGateway'
          // console.log(clientname)
          const path = `/client/${clientname}`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              const clientData = {}
              clientData.value = response.data
              if (clientData.value.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                clientData.value.status = 'active'
              } else if (clientData.value.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                clientData.value.status = 'disabled'
              }
              // console.log(clientData.value)
              theResponse.data = clientData.value
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'clients')) {
          const apiName = 'APIGateway'
          const path = '/client'

          API.post(apiName, path, { body: clientData })
            .then(response => {
              // eslint-disable-next-line no-param-reassign
              clientData.clientname = response.clientname
              // Add new template to the local storage to avoid call to backend
              const newClients = JSON.parse(localStorage.getItem('clients'))
              if (clientData.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                clientData.status = 'active'
              } else if (clientData.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                clientData.status = 'disabled'
              }
              newClients.push(clientData)
              localStorage.setItem('clients', JSON.stringify(newClients))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'clients')) {
          const apiName = 'APIGateway'
          const path = '/client'

          API.patch(apiName, path, { body: clientData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              const oldClients = JSON.parse(localStorage.getItem('clients'))
              const newClients = []
              oldClients.forEach(item => {
                console.log(item)
                if (item.clientname === clientData.clientname) {
                  if (clientData.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    clientData.status = 'active'
                  } else if (clientData.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    clientData.status = 'disabled'
                  }
                  newClients.push(clientData)
                } else {
                  newClients.push(item)
                }
              })
              localStorage.setItem('clients', JSON.stringify(newClients))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchClientSites(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'clients') || ability.can('read', 'cameras')) {
          console.log('fetchClientSites')
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.clients.fetchingClientSitesList = true
          const d1 = new Date()
          const timeDiff = d1.getTime() + 86400 // cache for 24 hours
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'sitename') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.sitename.localeCompare(a.sitename)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort sitename asc')
                  return a.sitename.localeCompare(b.sitename)
                })
              }
            }
            if (qvars.sortBy === 'country') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort country desc')
                  return b.country.localeCompare(a.country)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort country asc')
                  return a.country.localeCompare(b.country)
                })
              }
            }
            if (qvars.sortBy === 'province') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort province desc')
                  return b.province.localeCompare(a.province)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort country asc')
                  return a.province.localeCompare(b.province)
                })
              }
            }
            if (qvars.sortBy === 'status') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort status desc')
                  return b.status.localeCompare(a.status)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort status asc')
                  return a.status.localeCompare(b.status)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              console.log(item.status)
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              if (qvars.q !== null
                && !item.sitename.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.country.includes(qvars.q.toUpperCase())
                && !item.province.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          let doRefresh = false
          if (forceRefresh) {
            doRefresh = true
          } else if (qvars.clientname === '' && (localStorage.getItem('clientsites') === null || localStorage.getItem('clientsites_lastRefresh') > timeDiff)) {
            doRefresh = true
          } else if (qvars.clientname !== '' && (localStorage.getItem(`${qvars.clientname}-clientsites`) === null || localStorage.getItem(`${qvars.clientname}-clientsites_lastRefresh`) > timeDiff)) {
            doRefresh = true
          }
          if (doRefresh) {
            console.log('Refreshing Client Sites')
            const apiName = 'APIGateway'
            const path = `/client/${qvars.clientname}/site`
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                items.forEach(element => {
                  if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'active'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                })
                // Store User data in local storage
                localStorage.setItem(`${qvars.clientname}-clientsites`, JSON.stringify(items))
                localStorage.setItem(`${qvars.clientname}-clientsites_lastRefresh`, d1.getTime())
                theResponse.data.clientSites = resortFilterItems(items)
                theResponse.data.totalClientSites = totalItems
                // console.log(theResponse)
                setTimeout(() => {
                  store.state.clients.fetchingClientSitesList = false
                }, 10000)
                resolve(theResponse)
              })
              .catch(error => {
                store.state.clients.fetchingClientSitesList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Client Sites Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem(`${qvars.clientname}-clientsites`))
            theResponse.data.clientSites = resortFilterItems(itemsData)
            theResponse.data.totalClientSites = totalItems
            // console.log(theResponse)
            store.state.clients.fetchingClientSitesList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchAllClientSites(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'clients') || ability.can('read', 'cameras')) {
          console.log('fetchAllClientSites')
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.clients.fetchingClientSitesList = true
          const d1 = new Date()
          const timeDiff = d1.getTime() + 86400 // cache for 24 hours
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'sitename') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.sitename.localeCompare(a.sitename)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort sitename asc')
                  return a.sitename.localeCompare(b.sitename)
                })
              }
            }
            if (qvars.sortBy === 'client_display_name') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort client_display_name desc')
                  return b.client_display_name.localeCompare(a.client_display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort client_display_name asc')
                  return a.client_display_name.localeCompare(b.client_display_name)
                })
              }
            }
            if (qvars.sortBy === 'status') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort status desc')
                  return b.status.localeCompare(a.status)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort status asc')
                  return a.status.localeCompare(b.status)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              console.log(item.status)
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              if (qvars.q !== null
                && !item.sitename.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.client_display_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          let doRefresh = false
          if (forceRefresh) {
            doRefresh = true
          } else if (localStorage.getItem('allClientsites') === null || localStorage.getItem('allClientsites_lastRefresh') > timeDiff) {
            doRefresh = true
          }
          if (doRefresh) {
            console.log('Refreshing Client Sites')
            const apiName = 'APIGateway'
            const path = '/client/sites'
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                items.forEach(element => {
                  if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'active'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                })
                // Store User data in local storage
                localStorage.setItem('allClientsites', JSON.stringify(items))
                localStorage.setItem('allClientsites_lastRefresh', d1.getTime())
                theResponse.data.clientSites = resortFilterItems(items)
                theResponse.data.totalClientSites = totalItems
                // console.log(theResponse)
                setTimeout(() => {
                  store.state.clients.fetchingClientSitesList = false
                }, 10000)
                resolve(theResponse)
              })
              .catch(error => {
                store.state.clients.fetchingClientSitesList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Client Sites Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem('allClientsites'))
            theResponse.data.clientSites = resortFilterItems(itemsData)
            theResponse.data.totalClientSites = totalItems
            // console.log(theResponse)
            store.state.clients.fetchingClientSitesList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchClientSite(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'clients')) {
          const apiName = 'APIGateway'
          // console.log(qvars)
          const path = `/client/${qvars.clientname}/site/${qvars.sitename}`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              const clientSiteData = {}
              clientSiteData.value = response.data
              if (clientSiteData.value.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                clientSiteData.value.status = 'active'
              } else if (clientSiteData.value.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                clientSiteData.value.status = 'disabled'
              }
              // console.log(clientSiteData.value)
              theResponse.data = clientSiteData.value
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addClientSite(ctx, clientSiteData) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'clients')) {
          const apiName = 'APIGateway'
          const path = `/client/${clientSiteData.clientname}/site`

          API.post(apiName, path, { body: clientSiteData })
            .then(response => {
              // Add new template to the local storage to avoid call to backend
              const newClients = JSON.parse(localStorage.getItem(`${clientSiteData.clientname}-clientsites`))
              if (clientSiteData.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                clientSiteData.status = 'active'
              } else if (clientSiteData.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                clientSiteData.status = 'disabled'
              }
              newClients.push(clientSiteData)
              localStorage.setItem(`${clientSiteData.clientname}-clientsites`, JSON.stringify(newClients))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateClientSite(ctx, clientSiteData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'clients')) {
          const apiName = 'APIGateway'
          const path = `/client/${clientSiteData.clientname}/site`

          API.patch(apiName, path, { body: clientSiteData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              const oldClients = JSON.parse(localStorage.getItem(`${clientSiteData.clientname}-clientsites`))
              const newClients = []
              oldClients.forEach(item => {
                if (item.sitename === clientSiteData.sitename) {
                  if (clientSiteData.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    clientSiteData.status = 'active'
                  } else if (clientSiteData.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    clientSiteData.status = 'disabled'
                  }
                  newClients.push(clientSiteData)
                } else {
                  newClients.push(item)
                }
              })
              localStorage.setItem(`${clientSiteData.clientname}-clientsites`, JSON.stringify(newClients))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    deleteClientSite(ctx, clientSiteData) {
      return new Promise((resolve, reject) => {
        if (ability.can('delete', 'clients')) {
          const apiName = 'APIGateway'
          const path = `/client/${clientSiteData.clientname}/site/${clientSiteData.sitename}`

          API.del(apiName, path)
            .then(response => {
              // Update in the local storage to avoid call to backend
              const oldClients = JSON.parse(localStorage.getItem(`${clientSiteData.clientname}-clientsites`))
              const newClients = []
              oldClients.forEach(item => {
                if (item.sitename !== clientSiteData.sitename) {
                  newClients.push(item)
                }
              })
              localStorage.setItem(`${clientSiteData.clientname}-clientsites`, JSON.stringify(newClients))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
